import React from "react";
import style from "./VisitorWrapper.module.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import WindowWrapper from "./WindowWrapper";
import { Helmet } from "react-helmet";
import favicon from './../../../assets/logo.svg';

export default ({ children }) => (
    <WindowWrapper>
      <Helmet
        title={"Lamiglo | Small Business Website Development"}
        meta={[
          {
            name: "description",
            content: "Professional website development for business. We excel in top of the notch websites & applications that are highly functional, unique and beautifully designed.",
          },
          {
            name: "charSet",
            content: "utf-8",
          },
          {
            name : "theme-color",
            content : "#351C4D"
          },
          {
            name : "HandheldFriendly",
            content : "true"
          },
          {
            name : "MobileOptimized",
            content : "320"
          },
          {
            name : "msapplication-TileColor",
            content : "#351C4D"
          },
          {
            name : "msapplication-TileImage",
            content : favicon
          }
        ]}
        link={[
          {rel: "icon", type: "image/png", sizes: "any", href: `${favicon}`},
          {rel: "shortcut icon", type: "image/svg+xml", href: favicon},
          {rel: "apple-touch-icon", type: "image/svg+xml", href: favicon},
          {rel: "canonical", href: "https://lamiglo.com"},
          {rel:"icon", type:"image/x-icon",href:"iVBORw0KGgoAAAANSUhEUgAAATIAAAEZBAMAAADmg7QDAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAL\n" +
              "EwAACxMBAJqcGAAAABhQTFRFAAAAdlKF/rR79auZ/85g/35fiK+82VNPQnYSewAAAAh0Uk5TAP//\n" +
              "///////VylQyAAAKLElEQVR4nO2dTXKjSBCFMbLCW2sza4VO0BG+gBc6QG/Y9xFm5+tPq2Wgfl7+\n" +
              "Z9mKmcmdBCRfvVdVQAHFNKXH0+mUnzQlfpPd4/VLdjfrV93IbjGO6DPmy1m9bkU2XLmLn2yscJff\n" +
              "ESAbxzaHyQZ5+gdMbyckGyPb5R5BsgGyXZLI0mWbV7JzmCwXbQPLIEt19LJHAlki2lyQnRPI8tAu\n" +
              "2WRZaJcqUshy0OYRZCloNZjSTpEsAW0eRBZHu7SRRRZFayVLJAseDTqwRLIQmlMyLV6ArAM7WzMM\n" +
              "qmqdZGawadDRPeJlGel+dpI583BsvnQZXq6Rev7dShYBm0jZPKnyvLxH2gVVsmQkmt3PbMlukSJa\n" +
              "K1kGGEazipbuZRraEMkINFOCeYxkGM0m2iDJMJpp+zki2eHtHlo0v2hGsI2MgIv2HIFaVpC9vf3o\n" +
              "Fvddrk202S1ZTQZ0i47j+htmQ9azdWg+0cxgPVlnaVC02duX9WQimudAwCy/6slaRzs/bWQzL9nV\n" +
              "RNagxUXjwK7vFjIBzUY2MZI9m8nqytb6aRRtJsluYJSdFFmtWlA0Mq4esgotKBoPRthJk3FoOWTP\n" +
              "n2RYNIasQhtg5wZmJytbwQDRNjBsJ0dWoflFO/6Efz9HyEo//aItiwCG7eTJSjQv2XFZkGjXIFnh\n" +
              "ZyOahQygVZJBOwWyEs0p2nKL7t+rSCbC7ev5RDv+IWtFqyUjzzduYRdNSbYsSLSrnoyWbl+jFk1p\n" +
              "54LQWskoO9cQ0RyiHVeyys8WTCIj2HY/n+xkywJE6ySTyTDavthu505WiOYAw7VtF81s53EBaNZa\n" +
              "xqBtC812LmWsf3aSqcCE6zyrnSoyJRhE25YZRTtqyN7VZOx1u42sAiPqmQEMoWEy0U4omeIMyIC2\n" +
              "ifZkIcNmTpWf7zayvl/blljsPEIzp1I0K1iP5hKNkqwQTSpW/z85ruYlq8+DnmXJjnBDgLYt0NtJ\n" +
              "mjltoqm2btlUdrJktJmraO/KctVLKNH0dkLJ1uEXscdgFKdE05Lh1Nswn9Qw6y6nli1o5xElnrdx\n" +
              "vmehk23IKjRiRF5Lhs3cx0aFvqwlq9BidhJm7qKxYD1ZWdcI0XRkpJnKuwMdWYUWsROSGe6o9GQl\n" +
              "Wi0atFOVuTYTidaLD8jKqibbSZKhwm43yM7NyuB1GERWoB0gWWknWdEYMzu0J1BESFb4Ce3UkLFm\n" +
              "tn6iRJCMEs1S0RBZeff63EjWJcJkOxq2U0GGslX31VswJdnup9dOvppVZDgRQUaI5iNDZhZkTzYy\n" +
              "QrSmmDSZVM2KekbUC4psF81X0RAZL5mabBMN2imS6asZqT5FhkVDFU1Lhs3kM/Hd7QHYKTYBqZoB\n" +
              "yXAZucMnIkNN4JXIh6oZlIxoTEY7+3zVQRld+ECyk0zWo2126iraU+kHIJPNVB1PRDsJsrXYUgMg\n" +
              "yKjTA5VoJNn661eTij+cK+8xGMj6ilaRCg2g2KeOrEVb/y7tJJtAIyLbAIpdKq8Rm85jLa6HbMv1\n" +
              "uT42s0ajwVrRlBXttS17lUwim+o8SjQd2Ykgq266ztjMcksWrPET2SmTvZbphAZwCxUYMVAuVDSa\n" +
              "bA/SzDWVOFCOx6PHklWHDzocZK/9zzboBvCZS3GrCA7IgSZAk6GsdAO4BZqxYu7WhGR8EwiTTU+9\n" +
              "ZHMvL7IzTGZ/uXrui4GGl+Jk5od+Z6CwUNF+dGQnxYXBvi8jWYmmI5soMqaVzUQ1Y8mKkqCT+Bwy\n" +
              "4RFWTFai8RUtRPabzUxWbKFrAk4ybRRk5+1PcH3xrWS7aEJF+/ynghlLdt7+DZHpdvzy9xeRncxk\n" +
              "Hx96MmznODKerSI7M2R9t5FA9sFY+m1kH5+hI9vt/Doykq0mO1vIpiQyAk0m+6kgOw0gm/C1w/Eh\n" +
              "yNDdoOFkLyqyWUM2acn+spJxBwMjGTqkl60hkWwX7fxgZNPjks0tGXgO9vHI6EP6v56MX/G/SQZa\n" +
              "0/9kJ/4Y8DBk3CMhdrLMtvm4ZNZ6xl/dZRwDyOQEGQu0Rfy4yQQkU0bCucYgsoTzszaK2u0gWzky\n" +
              "zmnLaNqdnWxeQS4aMmloL5NsJSnAzg9BVnjYky02sroPa8+9imQqMgAWuEZPJEOSBcY1EslYyexj\n" +
              "QXlkULJHIINgkTHHNDIItknmGKdVkondBpZsFJmlQzOY6SBrR6DCZLtkjnsoWWSwnu1Ll2FkchMA\n" +
              "ou2See7VpZGxE4167m9yZMaulvbSdU+YIjN3Gx1auURHxtw5j3RoLVr5v+/ZAx2ZhmsCV0z3WNLJ\n" +
              "yBceBbaay/uMi4JMz4VDei4INACGbO82zFd0XSAzjWSo2xD3K5J7nz/jyWTBjiJ7BYaqmZFMul2+\n" +
              "71egdz/nGCQ7SoYTL5q+DScTG+8CJdM8T1uFdrR/CzxDTreCtZqxZLpxssYjcoXWzOFkx3aPAhhn\n" +
              "Zi5ZLwYLZnw/oApbE5BOx1sw4zsVATK4VwYMSrZWs0wy7p14BGZ9d6cKU0UjJQHYopmZZNxL1Pnv\n" +
              "iL0Y7EQ7Z2Pb0lHNLBWNfOeQCiwZqGYSmaCYXTMomf79TUNHaxRtbx8SGb47/WI4njslc74nLD6S\n" +
              "V4RJNEIyI5n+fDYqmeV9dO35jxFt38b9Dr8FTO9ncXhwmmmNoGS2uSJGoBUbvEEyhZnWUPlZeBmZ\n" +
              "k0SOQzmVqwKtPAfBkmV9G7aemtcEFpr7RoxDkVKDVhdKNDNAVuWU0dpCjTPzUCWV0OorF0KyJLIm\n" +
              "65+Q54cAYNlmHtq095CdzJj/jI0u72fwUyvVmw4x89AnJti6a+OEefa4QGXeg/IRgWHJ3GbCK0Vd\n" +
              "vI0lI/xwgCWb2TgC/FRuOVgyA1raXKvq/Eq0vPlpqeh2oKtqiXP6enZh2oqQLHieYTU0d+5oy14E\n" +
              "tOT5tskAu+HR0AahOcqp0HzvRwTbi5JmJrUrik1cOc9M5Qd/WK7o9wPMaI1u9GpFGTIlm5jPPGy7\n" +
              "HfudiiaKzaSPdwhBSuYzs9ouhFaAZUjWzMIZIauKmyFZlmhNcaNk3SycbrSyb6nBfGb2hXKilWB5\n" +
              "ktWl8pH1xc2QrClWFCxTsjAaA+YjIyQ3VzUqqdtMctjZiEYmzZCsKZoJjUmaIFmXIgssLlmXwwcW\n" +
              "/P6mLonK0fasMkWyLosDTQJznpjJX7K1ORn+Nu4eXQn7RBau8PeEbZlIS/vrlj6d/8KkSwULqdJL\n" +
              "nU0XfSmJZDIWAotcy/XZ3OVMTHULIJr3CixXMpjQhZaVZw8kmiMlyhIdMUhBGwGGsxrRElKggKJZ\n" +
              "8uIEGQ9AwMT6zBgs5wEInFqZO7SxFESpNbIFNlUFkV4uuXvDOBq7C99WtqBMYYxxbJKOhiRgV88E\n" +
              "E3ZV7Y4vBC7IWDR1JIMppPgmsDS03EqWiDYCLAVtDFgC2iiwcAMdBxZEGwkWMnQsWABtNJgbbTyY\n" +
              "E+0LuG7xsGBm2b7CyS0eUrB7qGX7UsHu8ZCCqdm+h+sWrKff4GMVjydXEWnD/MMiSah/AI19r+9y\n" +
              "TaQxAAAAAElFTkSuQmCC"}
        ]}
        >
        <html lang="en" />
      </Helmet>
        <Header/>
        <div className={style.paddinger}></div>
        {children}
        <Footer/>
    </WindowWrapper>
);